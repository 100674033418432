<template>
  <div class="container">
    <div class="card">
      <div :class="'card-header '+ cardStyle">
        Verificación
      </div>
      <div class="card-body">
        <img src="../assets/logo_biometrika.png" style="max-height: 50px" />
        <iproov-me
          :token="props.iProovmeToken"
          :language="JSON.stringify(languageEs)"
          base_url="https://us.rp.secure.iproov.me"
          custom_title="Biometrika"
          logo="../assets/logo_biometrika.png"
          close_button="https://www.materialui.co/materialIcons/navigation/close_black_72x72.png"
          show_countdown="true"
          enable_camera_selector="true"
          class="my-3"
        >
          <div slot="error">
            <h3>Ha ocurrido un error al intentar verificar:</h3>
            <h6 v-if="error">{{ error }}</h6>
          </div>
          <div slot="failed">
            <h3>Ha ocurrido un error al intentar verificar:</h3>
            <h6 v-if="error">{{ error }}</h6>
          </div>
          <div slot="passed">
            <h3>Proceso completado correctamente.</h3>
            <h6 v-if="error">{{ error }}</h6>
          </div>

          <!-- <div slot="camera_selector">
            <label for="camera-selector" class="camera-selector">Seleccione Cámara</label>
            <select class="form-control camera-selector" id="camera-selector"></select>
          </div> -->

          <div slot="button">
            <button type="button" class="btn btn-info text-white">
              Escanear Rostro
            </button>
          </div>
          <div slot="grant_button">
            <button type="button" class="btn btn-info text-white">
              Permitir Acceso a Cámara
            </button>
          </div>
        </iproov-me>
      </div>
      <div :class="'card-footer '+ cardStyle">
        Biometrika - iProov &copy; {{ new Date().getFullYear() }}
      </div>
    </div>
    <div v-if="done" class="alert alert-info mt-3" role="alert">
          Ya puede cerrar la página.
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import languageEs from "../assets/languages/iproov-es.json";

require("@iproov/web-sdk");
const props = defineProps(["iProovmeToken"]);
const emit = defineEmits(['isFinished']);
// const events = [];
const error = ref(null);
let done = false;
let cardStyle = 'bg-light';

onMounted(() => {
  const iProovMe = document.querySelector("iproov-me");
  iProovMe.addEventListener("ready", iProovEvent);
  iProovMe.addEventListener("started", iProovEvent);
  iProovMe.addEventListener("canceled", iProovEvent);
  iProovMe.addEventListener("streaming", iProovEvent);
  iProovMe.addEventListener("streamed", iProovEvent);
  iProovMe.addEventListener("progress", iProovEvent);
  iProovMe.addEventListener("passed", iProovEvent);
  iProovMe.addEventListener("failed", iProovEvent);
  iProovMe.addEventListener("error", iProovEvent);
  iProovMe.addEventListener("unsupported", iProovEvent);
  iProovMe.addEventListener("permission", iProovEvent);
  iProovMe.addEventListener("permission_denied", iProovEvent);
});

function iProovEvent(event) {
  // events.push(event);
  switch (event.type) {
    case "canceled":
    case "error":
    case "unsupported":
    case "permission_denied":
      cardStyle = 'bg-warning';
      done = true;
      error.value = event.detail.reason;
      console.warn("iProov " + event.type + " - " + event.detail.reason);
      break;
    case "permission":
      break;
    case "progress":
      console.info(event.detail.message + " (" + event.detail.progress + "%)");
      break;
    case "passed":
    case "failed":
      cardStyle = event.type == 'passed' ? 'bg-success text-white':'bg-danger text-white'
      done = true;
      error.value = event.detail.reason;
      console.log("iProov " + event.detail.type + " " + event.type);
      emit('isFinished');
      break;
    default:
      console.log("iProov " + event.type);
  }
  
  // console.debug(events);
}
</script>

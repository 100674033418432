const bs7baseUrl = "https://qabs.biometrikalatam.com";
const axios = require("axios");

export async function initializeTx(trackId) {
  const endpoint = bs7baseUrl + "/api/v1/bpweb/initialize/" + trackId;

  try {
    let response = await axios.get(endpoint);
    if (response && response.data) {
      let bs7Data = response.data;
      let bpTxData = bs7Data.data?.bpTxData;
      let lastTx = bpTxData[bpTxData.length - 1];
      if (lastTx && lastTx.key) {
        switch (lastTx.key) {
          case "iProovToken":
            return lastTx.dataRaw;
        }
      }
    }
  } catch (err) {
    alert("Transacción expirada o inválida. Intente nuevamente.");
    console.log(err);
  }
}

export async function process(trackId) {
  const endpoint = bs7baseUrl + "/api/v1/bpweb/process";

  const bodyRequest = [
    {
      "Trackid": trackId,
      "Id": 0,
      "IsActive": false,
      "StatusCallback": false,
      "Name": "Inicio",
      "Key": "KEY_INIT",
      "Data": null,
      "Message": null,
      "Type": null,
      "ComponentName": "InitComp",
      "Parameter": {
        "ValueKind": 3
      },
      "BKResponse": {
        "Code": 0,
        "Message": null,
        "Data": {
          "ValueKind": 5
        }
      }
    },
    {
      "Trackid": trackId,
      "Id": 2,
      "IsActive": true,
      "StatusCallback": false,
      "Name": "Liveness",
      "Key": "KEY_SELFIE3D",
      "Data": "OK",
      "Message": null,
      "Type": null,
      "ComponentName": "iProovComponent",
      "Parameter": {
        "ValueKind": 3
      },
      "BKResponse": {
        "Code": 0,
        "Message": "",
        "Data": null
      }
    },
    {
      "Trackid": trackId,
      "Id": 3,
      "IsActive": true,
      "StatusCallback": false,
      "Name": "Fin",
      "Key": "KEY_END",
      "Data": "true",
      "Message": null,
      "Type": null,
      "ComponentName": "EndComp",
      "Parameter": {
        "ValueKind": 3
      },
      "BKResponse": {
        "Code": 0,
        "Message": "",
        "Data": null
      }
    }
  ];

  try {
    let response = await axios.post(endpoint, bodyRequest);
    if (response){
      console.debug(response);
    }
  } catch (err) {
    console.debug(err);
  }
}

import "bootstrap/dist/css/bootstrap.min.css";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import IProovMeComponent from './components/IProovMeComponent.vue'

createApp(App).use(store).use(router).component('IProovMeComponent', IProovMeComponent).mount('#app')

import "bootstrap/dist/js/bootstrap.min";
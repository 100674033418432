<template>
  <div class="container">
    <template v-if="loading">
      <h2>Iniciando transacción</h2>
      <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    </template>
    <template v-else>
      <IProovMeComponent v-if="iProovMeToken" :i-proovme-token="iProovMeToken" @is-finished="processTx" />
      <div v-else>
        <img src="../assets/logo_biometrika.png" class="mb-4" style="max-height: 60px" />
        <h2>
          Enlace incorrecto o caducado, solicite una nueva verificación e intente
          nuevamente.
        </h2>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { initializeTx, process } from "../bs7service";

let bs7trackId = null;
const iProovMeToken = ref("");
const loading = ref(true);

onMounted(() => {
    bs7trackId = getBs7trackId();
    if (bs7trackId){
      getiProovMeToken();
    } else {
      loading.value = false;
    }
  });
  
async function getiProovMeToken(){
  iProovMeToken.value = await initializeTx(bs7trackId);
  loading.value = false;
}

async function processTx() {
    process(bs7trackId);
}


function getBs7trackId() {
  const route = useRoute();
  let { trackId } = route.query;
  if (trackId && trackId.length === 32) {
    return trackId;
  }
}



</script>

<style></style>
